import React from "react";
import "./Imprint.css";

export const Imprint = () => {
  return (
    <div className="impressum">
      <div className="impContent">
        <div className="impText">
          <h2>Impressum</h2>
          <p>
            inh. Stefan Trost
            <br />
            Schramberger Weg 8<br />
            78733 Aichhalden
            <br />
            <br />
            E-Mail: info@arton-design.de
            <br />
            Internet: https://arton-design.de
            <br />
            USt-IdNr.: DE614970327
          </p>
        </div>
        <div className="privacy">
          <h2>Datenschutzbestimmungen</h2>
          <h4>I. Name und Anschrift des Verantwortlichen</h4>
          Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
          anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
          sonstiger datenschutzrechtlicher Bestimmungen ist:
          <br />
          <br />
          <strong>Stefan Trost</strong>
          <br />
          <strong>Schramberger Weg 8</strong>
          <br />
          <strong>78733 Aichhalden</strong>
          <br />
          Email: info@arton-design.de
          <br />
          <h4>II. Allgemeines zur Datenverarbeitung</h4>
          <h5>1. Umfang der Verarbeitung personenbezogener Daten</h5>
          Wir erheben und verwenden personenbezogene Daten unserer Nutzer
          grundsätzlich nur, soweit dies zur Bereitstellung einer
          funktionsfähigen Website sowie unserer Inhalte und Leistungen
          erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten
          unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers.
          Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige
          Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich
          ist und die Verarbeitung der Daten durch gesetzliche Vorschriften
          gestattet ist.
          <h5>
            2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
          </h5>
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
          Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
          lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für
          die Verarbeitung personenbezogener Daten. Bei der Verarbeitung von
          personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
          Vertragspartei die betroffene Person ist, erforderlich ist, dient
          Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
          Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
          erforderlich sind. Soweit eine Verarbeitung personenbezogener Daten
          zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der
          unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
          Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der
          betroffenen Person oder einer anderen natürlichen Person eine
          Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
          Abs. 1 lit. d DSGVO als Rechtsgrundlage. Ist die Verarbeitung zur
          Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
          Dritten erforderlich und überwiegen die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so
          dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die
          Verarbeitung.
          <h5>3. Datenlöschung und Speicherdauer</h5>
          Die personenbezogenen Daten der betroffenen Person werden gelöscht
          oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
          Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den
          europäischen oder nationalen Gesetzgeber in unionsrechtlichen
          Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
          Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
          Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
          Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen
          Vertragsabschluss oder eine Vertragserfüllung besteht.
          <h4>III. Bereitstellung der Website und Erstellung von Logfiles</h4>
          <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
          Bei jedem Aufruf unserer Internetseite oder unserer Applikation
          erfasst unser System automatisiert Daten und Informationen vom
          Computersystem des aufrufenden Rechners. Folgende Daten werden hierbei
          erhoben: Informationen über den Browsertyp und die verwendete Version
          Das Betriebssystem des Nutzers Den Internet-Service-Provider des
          Nutzers Datum und Uhrzeit des Zugriffs Websites, von denen das System
          des Nutzers auf unsere Internetseite gelangt Websites, die vom System
          des Nutzers über unsere Website aufgerufen werden Die Daten werden
          ebenfalls in den Logfiles unseres Systems gespeichert. Nicht hiervon
          betroffen sind die IP-Adressen des Nutzers oder andere Daten, die die
          Zuordnung der Daten zu einem Nutzer ermöglichen. Eine Speicherung
          dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers
          findet nicht statt.
          <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
          Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
          Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
          <h5>3. Zweck der Datenverarbeitung</h5>
          Die vorübergehende Speicherung der IP-Adresse durch das System ist
          notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
          zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
          der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt,
          um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen
          uns die Daten zur Optimierung der Website und zur Sicherstellung der
          Sicherheit unserer informationstechnischen Systeme. Eine Auswertung
          der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
          statt. In diesen Zwecken liegt auch unser berechtigtes Interesse an
          der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
          <h5>4. Dauer der Speicherung</h5>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
          der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
          jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in
          Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
          darüberhinausgehende Speicherung ist möglich. In diesem Fall werden
          die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine
          Zuordnung des aufrufenden Clients nicht mehr möglich ist.
          <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
          Die Erfassung der Daten zur Bereitstellung der Website und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internetseite zwingend erforderlich. Es besteht folglich seitens des
          Nutzers keine Widerspruchsmöglichkeit.
          <h4>IV. Verwendung von Cookies</h4>
          <h5>a) Beschreibung und Umfang der Datenverarbeitung</h5>
          Unsere Webeseite verwendet Cookies. Bei Cookies handelt es sich um
          Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
          Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
          Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
          gespeichert werden. Dieser Cookie enthält eine charakteristische
          Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
          erneuten Aufrufen der Website ermöglicht. Wir setzen Cookies ein, um
          unsere Website nutzerfreundlicher zu gestalten. Einige Elemente
          unserer Internetseite erfordern es, dass der aufrufende Browser auch
          nach einem Seitenwechsel identifiziert werden kann. In den Cookies
          werden dabei folgende Daten gespeichert und übermittelt:
          Seiteneinstellungen Artikel im Warenkorb Wir verwenden auf unserer
          Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens
          der Nutzer ermöglichen. Auf diese Weise können folgende Daten
          übermittelt werden: Eingegebene Suchbegriffe Häufigkeit von
          Seitenaufrufen Inanspruchnahme von Website-Funktionen Die auf diese
          Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen
          pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden
          Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam mit
          sonstigen personenbezogenen Daten der Nutzer gespeichert. Beim Aufruf
          unserer Website werden die Nutzer durch einen Infobanner über die
          Verwendung von Cookies zu Analysezwecken informiert und auf diese
          Datenschutzerklärung verwiesen. Es erfolgt in diesem Zusammenhang auch
          ein Hinweis darauf, wie die Speicherung von Cookies in den
          Browsereinstellungen unterbunden werden kann. Beim Aufruf unserer
          Website wird der Nutzer über die Verwendung von Cookies zu
          Analysezwecken informiert und seine Einwilligung zur Verarbeitung der
          in diesem Zusammen verwendeten personenbezogenen Daten eingeholt. In
          diesem Zusammenhang erfolgt auch ein Hinweis auf diese
          Datenschutzerklärung.
          <strong>b) Rechtsgrundlage für die Datenverarbeitung</strong>
          Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
          Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f
          DSGVO. Die Rechtsgrundlage für die Verarbeitung personenbezogener
          Daten unter Verwendung von Cookies zu Analysezwecken ist bei Vorliegen
          einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 lit. a
          DSGVO.
          <strong>c) Zweck der Datenverarbeitung</strong>
          Der Zweck der Verwendung technisch notwendiger Cookies ist, die
          Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen
          unserer Internetseite können ohne den Einsatz von Cookies nicht
          angeboten werden. Für diese ist es erforderlich, dass der Browser auch
          nach einem Seitenwechsel wiedererkannt wird. Für folgende Anwendungen
          benötigen wir Cookies: Warenkorb Übernahme von Seiteneinstellungen
          Merken von Suchbegriffen Die durch technisch notwendige Cookies
          erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen
          verwendet. Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck,
          die Qualität unserer Website und ihre Inhalte zu verbessern. Durch die
          Analyse-Cookies erfahren wir, wie die Website genutzt wird und können
          so unser Angebot stetig optimieren. In diesen Zwecken liegt auch unser
          berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten
          nach Art. 6 Abs. 1 lit. f DSGVO.
          <strong>
            e) Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
          </strong>
          Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem
          an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die
          volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung
          der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung
          von Cookies deaktivieren oder einschränken. Bereits gespeicherte
          Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert
          erfolgen. Werden Cookies für unsere Website deaktiviert, können
          möglicherweise nicht mehr alle Funktionen der Website vollumfänglich
          genutzt werden.
          <h4>V. Newsletter</h4>
          <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
          Auf unserer Internetseite besteht die Möglichkeit einen kostenfreien
          Newsletter zu abonnieren. Dabei werden bei der Anmeldung zum
          Newsletter die Daten aus der Eingabemaske an uns übermittelt.
          Email-Adresse Nachricht Zudem werden folgende Daten bei der Anmeldung
          erhoben: IP-Adresse des aufrufenden Rechners Datum und Uhrzeit der
          Registrierung Für die Verarbeitung der Daten wird im Rahmen des
          Anmeldevorgang Ihre Einwilligung eingeholt und auf diese
          Datenschutzerklärung verwiesen. Es erfolgt im Zusammenhang mit der
          Datenverarbeitung für den Versand von Newslettern keine Weitergabe der
          Daten an Dritte. Die Daten werden ausschließlich für den Versand des
          Newsletters verwendet.
          <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
          Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum
          Newsletters durch den Nutzer ist bei Vorliegen einer Einwilligung des
          Nutzers Art. 6 Abs. 1 lit. a DSGVO.
          <h5>3. Zweck der Datenverarbeitung</h5>
          Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter
          zuzustellen. Die Erhebung sonstiger personenbezogener Daten im Rahmen
          des Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder der
          verwendeten E-Mail-Adresse zu verhindern.
          <h5>4. Dauer der Speicherung</h5>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Die E-Mail-Adresse des
          Nutzers wird demnach solange gespeichert, wie das Abonnement des
          Newsletters aktiv ist. Die sonstigen im Rahmen des Anmeldevorgangs
          erhobenen personenbezogenen Daten werden in der Regel nach einer Frist
          von sieben Tagen gelöscht.
          <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
          Das Abonnement des Newsletters kann durch den betroffenen Nutzer
          jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem
          Newsletter ein entsprechender Link. Hierdurch wird ebenfalls ein
          Widerruf der Einwilligung der Speicherung der während des
          Anmeldevorgangs erhobenen personenbezogenen Daten ermöglicht.
          <h4>VI. Registrierung</h4>
          <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
          Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich
          unter Angabe personenbezogener Daten zu registrieren. Die Daten werden
          dabei in eine Eingabemaske eingegeben und an uns übermittelt und
          gespeichert. Eine Weitergabe der Daten an Dritte findet nicht statt.
          Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:
          Name, Email-Adresse, Passwort Im Zeitpunkt der Registrierung werden
          zudem folgende Daten gespeichert: Die IP-Adresse des Nutzers Datum und
          Uhrzeit der Registrierung Im Rahmen des Registrierungsprozesses wird
          eine Einwilligung des Nutzers zur Verarbeitung dieser Daten eingeholt.
          <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Dient die
          Registrierung der Erfüllung eines Vertrages, dessen Vertragspartei der
          Nutzer ist oder der Durchführung vorvertraglicher Maßnahmen, so ist
          zusätzliche Rechtsgrundlage für die Verarbeitung der Daten Art. 6
          Abs. 1 lit. b DSGVO.
          <h5>3. Zweck der Datenverarbeitung</h5>
          Eine Registrierung des Nutzers ist für das Bereithalten bestimmter
          Inhalte und Leistungen auf unserer Website erforderlich.
          Bereitstellung eines Nutzerprofils Interaktion mit anderen Nutzern der
          Applikation
          <h5>4. Dauer der Speicherung</h5>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Dies ist für die während
          des Registrierungsvorgangs erhobenen Daten der Fall, wenn die
          Registrierung auf unserer Internetseite aufgehoben oder abgeändert
          wird.
          <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
          Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung
          aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit
          abändern lassen. Der Account kann jederzeit über die Applikation, über
          die Website oder per Aufforderung per Mail gelöscht werden.
          <h4>VII. Kontaktformular und E-Mail-Kontakt</h4>
          <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
          Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches
          für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein
          Nutzer diese Möglichkeit war, so werden die in der Eingabemaske
          eingegeben Daten an uns übermittelt und gespeichert. Diese Daten sind:
          Email-Adresse, Name, Nachricht Im Zeitpunkt der Absendung der
          Nachricht werden zudem folgende Daten gespeichert: Die IP-Adresse des
          Nutzers Datum und Uhrzeit der Registrierung Für die Verarbeitung der
          Daten wird im Rahmen des Absendevorgangs Ihre Einwilligung eingeholt
          und auf diese Datenschutzerklärung verwiesen. Alternativ ist eine
          Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In
          diesem Fall werden die mit der E-Mail übermittelten personenbezogenen
          Daten des Nutzers gespeichert. Es verfolgt in diesem Zusammenhang
          keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich
          für die Verarbeitung der Konversation verwendet.
          <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage
          für die Verarbeitung der Daten, die im Zuge einer Übersendung einer
          E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der
          E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist
          zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
          DSGVO.
          <h5>3. Zweck der Datenverarbeitung</h5>
          Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
          dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
          Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
          berechtigte Interesse an der Verarbeitung der Daten. Die sonstigen
          während des Absendevorgangs verarbeiteten personenbezogenen Daten
          dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und
          die Sicherheit unserer informationstechnischen Systeme
          sicherzustellen.
          <h5>4. Dauer der Speicherung</h5>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
          Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die
          per E-Mail übersandt wurden, ist dies dann der Fall, wenn die
          jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die
          Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass
          der betroffene Sachverhalt abschließend geklärt ist. Die während des
          Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden
          spätestens nach einer Frist von sieben Tagen gelöscht.
          <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
          Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
          Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
          Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung
          seiner personenbezogenen Daten jederzeit widersprechen. In einem
          solchen Fall kann die Konversation nicht fortgeführt werden. Es folgt
          eine Beschreibung, auf welche Weise der Widerruf der Einwilligung und
          der Widerspruch der Speicherung ermöglicht wird. Alle
          personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert
          wurden, werden in diesem Fall gelöscht.
          <h4>VIII. Rechte der betroffenen Person</h4>
          Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
          Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
          dem Verantwortlichen zu:
          <h4>1. Auskunftsrecht</h4>
          Sie können von dem Verantwortlichen eine Bestätigung darüber
          verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
          verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von
          dem Verantwortlichen über folgende Informationen Auskunft verlangen:
          (1)        die Zwecke, zu denen die personenbezogenen Daten
          verarbeitet werden; (2)        die Kategorien von personenbezogenen
          Daten, welche verarbeitet werden; (3)        die Empfänger bzw. die
          Kategorien von Empfängern, gegenüber denen die Sie betreffenden
          personenbezogenen Daten offengelegt wurden oder noch offengelegt
          werden; (4)        die geplante Dauer der Speicherung der Sie
          betreffenden personenbezogenen Daten oder, falls konkrete Angaben
          hierzu nicht möglich sind, Kriterien für die Festlegung der
          Speicherdauer; (5)        das Bestehen eines Rechts auf Berichtigung
          oder Löschung der Sie betreffenden personenbezogenen Daten, eines
          Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen
          oder eines Widerspruchsrechts gegen diese Verarbeitung; (6)        das
          Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde; (7)       
          alle verfügbaren Informationen über die Herkunft der Daten, wenn die
          personenbezogenen Daten nicht bei der betroffenen Person erhoben
          werden; (8)        das Bestehen einer automatisierten
          Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und
          4 DSGVO und – zumindest in diesen Fällen – aussagekräftige
          Informationen über die involvierte Logik sowie die Tragweite und die
          angestrebten Auswirkungen einer derartigen Verarbeitung für die
          betroffene Person. Ihnen steht das Recht zu, Auskunft darüber zu
          verlangen, ob die Sie betreffenden personenbezogenen Daten in ein
          Drittland oder an eine internationale Organisation übermittelt werden.
          In diesem Zusammenhang können Sie verlangen, über die geeigneten
          Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung
          unterrichtet zu werden.
          <h4>2. Recht auf Berichtigung</h4>
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
          gegenüber dem Verantwortlichen, sofern die verarbeiteten
          personenbezogenen Daten, die Sie betreffen, unrichtig oder
          unvollständig sind. Der Verantwortliche hat die Berichtigung
          unverzüglich vorzunehmen.
          <h4>3.Recht auf Einschränkung der Verarbeitung</h4>
          Unter den folgenden Voraussetzungen können Sie die Einschränkung der
          Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
          (1)        wenn Sie die Richtigkeit der Sie betreffenden
          personenbezogenen für eine Dauer bestreiten, die es dem
          Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
          Daten zu überprüfen; (2)        die Verarbeitung unrechtmäßig ist und
          Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen
          die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
          (3)        der Verantwortliche die personenbezogenen Daten für die
          Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          benötigen, oder (4)        wenn Sie Widerspruch gegen die Verarbeitung
          gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht,
          ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren
          Gründen überwiegen. Wurde die Verarbeitung der Sie betreffenden
          personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer
          Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder
          zum Schutz der Rechte einer anderen natürlichen oder juristischen
          Person oder aus Gründen eines wichtigen öffentlichen Interesses der
          Union oder eines Mitgliedstaats verarbeitet werden. Wurde die
          Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
          eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor
          die Einschränkung aufgehoben wird.
          <h5>4. Recht auf Löschung</h5>
          a)        Löschungspflicht Sie können von dem Verantwortlichen
          verlangen, dass die Sie betreffenden personenbezogenen Daten
          unverzüglich gelöscht werden, und der Verantwortliche ist
          verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der
          folgenden Gründe zutrifft: (1)        Die Sie betreffenden
          personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder
          auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
          (2)        Sie widerrufen Ihre Einwilligung, auf die sich die
          Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO
          stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
          Verarbeitung. (3)        Sie legen gem. Art. 21 Abs. 1 DSGVO
          Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen
          berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem.
          Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
          (4)        Die Sie betreffenden personenbezogenen Daten wurden
          unrechtmäßig verarbeitet. (5)        Die Löschung der Sie betreffenden
          personenbezogenen Daten ist zur Erfüllung einer rechtlichen
          Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
          erforderlich, dem der Verantwortliche unterliegt. (6)        Die Sie
          betreffenden personenbezogenen Daten wurden in Bezug auf angebotene
          Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO
          erhoben. b)       Information an Dritte Hat der Verantwortliche die
          Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er
          gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er
          unter Berücksichtigung der verfügbaren Technologie und der
          Implementierungskosten angemessene Maßnahmen, auch technischer Art, um
          für die Datenverarbeitung Verantwortliche, die die personenbezogenen
          Daten verarbeiten, darüber zu informieren, dass Sie als betroffene
          Person von ihnen die Löschung aller Links zu diesen personenbezogenen
          Daten oder von Kopien oder Replikationen dieser personenbezogenen
          Daten verlangt haben. c)        Ausnahmen Das Recht auf Löschung
          besteht nicht, soweit die Verarbeitung erforderlich ist (1)        zur
          Ausübung des Rechts auf freie Meinungsäußerung und Information;
          (2)        zur Erfüllung einer rechtlichen Verpflichtung, die die
          Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem
          der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
          Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
          öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
          wurde; (3)        aus Gründen des öffentlichen Interesses im Bereich
          der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
          Art. 9 Abs. 3 DSGVO; (4)        für im öffentlichen Interesse liegende
          Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder
          für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
          Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
          Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
          beeinträchtigt, oder (5)        zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen.
          <h5>5. Recht auf Unterrichtung</h5>
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
          Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
          dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
          personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
          Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
          sei denn, dies erweist sich als unmöglich oder ist mit einem
          unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
          Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
          werden.
          <h5>6. Recht auf Datenübertragbarkeit</h5>
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
          Sie dem Verantwortlichen bereitgestellt haben, in einem
          strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
          Außerdem haben Sie das Recht diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          (1)        die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1
          lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag
          gem. Art. 6 Abs. 1 lit. b DSGVO beruht und (2)        die Verarbeitung
          mithilfe automatisierter Verfahren erfolgt. In Ausübung dieses Rechts
          haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
          personenbezogenen Daten direkt von einem Verantwortlichen einem
          anderen Verantwortlichen übermittelt werden, soweit dies technisch
          machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch
          nicht beeinträchtigt werden. Das Recht auf Datenübertragbarkeit gilt
          nicht für eine Verarbeitung personenbezogener Daten, die für die
          Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
          Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
          Verantwortlichen übertragen wurde.
          <h5>7. Widerspruchsrecht</h5>
          Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung der Sie
          betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
          lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
          für ein auf diese Bestimmungen gestütztes Profiling. Der
          Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
          Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe
          für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
          Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen. Werden die Sie
          betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
          betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen
          Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
          betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
          verarbeitet. Sie haben die Möglichkeit, im Zusammenhang mit der
          Nutzung von Diensten der Informationsgesellschaft – ungeachtet der
          Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter
          Verfahren auszuüben, bei denen technische Spezifikationen verwendet
          werden.
          <h5>
            8. Recht auf Widerruf der datenschutzrechtlichen
            Einwilligungserklärung
          </h5>
          Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
          jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung nicht berührt.
          <h5>
            9. Automatisierte Entscheidung im Einzelfall einschließlich
            Profiling
          </h5>
          Sie haben das Recht, nicht einer ausschließlich auf einer
          automatisierten Verarbeitung – einschließlich Profiling – beruhenden
          Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
          Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
          beeinträchtigt. Dies gilt nicht, wenn die Entscheidung (1)        für
          den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem
          Verantwortlichen erforderlich ist, (2)        aufgrund von
          Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
          Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften
          angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie
          Ihrer berechtigten Interessen enthalten oder (3)        mit Ihrer
          ausdrücklichen Einwilligung erfolgt. Allerdings dürfen diese
          Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten
          nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a
          oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und
          Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
          Hinsichtlich der in (1) und (3) genannten Fälle trifft der
          Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
          sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das
          Recht auf Erwirkung des Eingreifens einer Person seitens des
          Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
          Anfechtung der Entscheidung gehört.
          <h5>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
          Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
          einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
          Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
          Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. Die
          Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
          unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse
          der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
          Rechtsbehelfs nach Art. 78 DSGVO.
        </div>
      </div>
    </div>
  );
};
