import "./Home.css";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "./assets/logo.png";
function Home() {
  const [data, setdata] = useState({
    link: "",
    videoUrl: null,
    imageUrl: null,
    error: false,
  });
  const [loading, setLoading] = useState(false);

  const downloadVideo = async () => {
    window.gtag("event", "load_video", {
      method: "load video",
    });

    try {
      setdata({ ...data, error: false });

      if (validURL(data.link)) {
        setLoading(true);
        const res = await fetch(data.link);
        let html = await res.text();
        let re = /og:video" content="[^"]*/gm;
        let result = html.match(re);
        let first = result[0].split('content="');
        var lastItem = first[first.length - 1];
        var videoUrl = lastItem;

        re = /og:image" content="[^"]*/gm;
        result = html.match(re);
        first = result[0].split('content="');
        lastItem = first[first.length - 1];
        let imageUrl = lastItem;

        setdata({ ...data, videoUrl, imageUrl, error: false });
        setLoading(false);
      } else {
        setdata({
          ...data,
          error: true,
          errorMessage:
            "Der eingegebene Link scheint kein gültiger Instagram Link zu sein",
        });
        setLoading(false);
      }
    } catch (error) {
      setdata({
        ...data,
        error: true,
        errorMessage: "Fehler beim Laden des Videos",
      });
      setLoading(false);

      console.log(error);
    }
  };

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str) && str.includes("instagram");
  };

  return (
    <div className="App">
      <div className="main">
        <div className="section section-lg header">
          <div className="background"></div>
          <div className="container">
            <div className="col">
              <div className="row">
                <div className="col-lg-12">
                  <div className="headline">
                    <img
                      className="logo"
                      alt="Instagram Video Downloader"
                      src={logo}
                    />
                    <div>
                      <h1>Instagram Video Downloader</h1>
                      <p>
                        Nutze diese Website um einfach, schnell und kostenlos
                        Instagram Videos herunterzuladen.
                      </p>
                    </div>
                  </div>

                  <div className="download">
                    <input
                      placeholder="Gib hier den Link deines Instagram-Videos ein"
                      value={data.link}
                      onChange={(e) =>
                        setdata({ ...data, link: e.target.value })
                      }
                    />
                    <button onClick={downloadVideo}>
                      <span>Video laden</span>
                      {loading ? (
                        <ClipLoader size={15} color={"#fff"} loading={true} />
                      ) : null}
                    </button>
                  </div>
                  {data.error ? (
                    <span className="error">{data.errorMessage}</span>
                  ) : null}

                  {data.imageUrl && !data.error ? (
                    <div className="media">
                      <img src={data.imageUrl} />
                      <button
                        onClick={() => {
                          window.gtag("event", "file_download", {
                            method: "open video",
                          });
                          window.open(data.videoUrl, "_blank");
                        }}
                      >
                        Download Instagram Video
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container more">
          <div className="description">
            <h2>Download videos from Instagram</h2>
            <p>
              Mit diesem Tool können Sie Instagram-Videos und Bilder speichern
              und auf Ihr Gerät herunterladen.
              <br />
              Sie brauchen nur die URL des Videos aus dem Instagram zu kopieren
              und in das obige Eingabefeld einzufügen.
              <br />
              <br /> Das Tool ist kostenlos und nicht mit Instagram oder
              Facebook verbunden. <br /> <br />
              Das Tool wird nur dazu verwendet, die URL zum Herunterladen der
              Videos anzuzeigen.
              <br /> Wir speichern niemals irgendeine Art von Video oder Inhalt
              auf unserem Server.
            </p>
          </div>
        </div>
      </div>
      <div className="footer">
        <ul>
          <li>
            <a href="/imprint">Impressum</a>
          </li>
          <li>
            <a href="/imprint">Privacy</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
